<template>
    <div>
        <b-alert :show="alert.show" :variant="alert.variant" fade>
            <span class="text-light-75 font-weight-bold d-block font-size-lg">{{
                alert.text
            }}</span>
        </b-alert>
        <!--begin::Advance Table Widget 10-->
        <div class="card card-custom gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 py-5">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label font-weight-bolder text-dark">{{
                        $t("BRAND.BRANDS")
                    }}</span>
                    <span
                        class="text-muted mt-3 font-weight-bold font-size-sm"
                        >{{
                            $tc("BRAND.SHOW_COUNT", paginatedBrands.length, {
                                total: brands.length,
                            })
                        }}</span
                    >
                </h3>
                <div class="card-toolbar">
                    <router-link :to="{ name: 'brand', params: { id: 'new' } }">
                        <b-button variant="primary">{{
                            $t("BRAND.NEW")
                        }}</b-button>
                    </router-link>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body py-0">
                <!--begin::Table-->
                <div class="table-responsive" v-if="brands.length">
                    <table
                        class="table table-head-custom table-vertical-center"
                        id="kt_advance_table_widget_4"
                    >
                        <thead>
                            <tr class="text-left">
                                <th class="pl-0" style="min-width: 80px">
                                    {{ $t("BRAND.LOGO") }}
                                </th>
                                <th style="min-width: 160px">
                                    {{ $t("BRAND.NAME") }}
                                </th>
                                <th style="min-width: 110px">
                                    {{ $t("BRAND.TAX_ID") }}
                                </th>
                                <th style="min-width: 110px">
                                    {{ $t("BRAND.STREET") }}
                                </th>
                                <th style="min-width: 120px">
                                    {{ $t("BRAND.CITY") }}
                                </th>
                                <th style="min-width: 120px">
                                    {{ $t("BRAND.ZIP") }}
                                </th>
                                <th style="min-width: 120px">
                                    {{ $t("BRAND.COUNTRY") }}
                                </th>
                                <th
                                    class="pr-0 text-right"
                                    style="min-width: 160px"
                                >
                                    {{ $t("ACTIONS") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="brand in paginatedBrands">
                                <tr v-bind:key="brand.id">
                                    <td class="pl-0">
                                        <div v-if="brand.logo" class="symbol">
                                            <img
                                                :src="brand.logo"
                                                style="
                                                    width: auto;
                                                    height: auto;
                                                    max-width: 50px;
                                                    max-height: 50px;
                                                "
                                            />
                                        </div>
                                        <span
                                            v-else
                                            class="label label-lg label-inline label-light-danger"
                                            >{{ $t("BRAND.NO_LOGO") }}</span
                                        >
                                    </td>
                                    <td>
                                        <router-link
                                            :to="{
                                                name: 'brand',
                                                params: { id: brand.id },
                                            }"
                                            class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                                            >{{ brand.name }}
                                        </router-link>
                                    </td>
                                    <td>
                                        <span
                                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                                            >{{ brand.tax_id }}</span
                                        >
                                    </td>
                                    <td>
                                        <span
                                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                                            >{{ brand.address.street }}</span
                                        >
                                    </td>
                                    <td>
                                        <span
                                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                                            >{{ brand.address.city }}</span
                                        >
                                    </td>
                                    <td>
                                        <span
                                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                                            >{{ brand.address.zip }}</span
                                        >
                                    </td>
                                    <td>
                                        <span
                                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                                            >{{ brand.address.country }}</span
                                        >
                                    </td>
                                    <td class="pr-0 text-right">
                                        <router-link
                                            :to="{
                                                name: 'brand',
                                                params: {
                                                    id: brand.id,
                                                    edit: true,
                                                },
                                            }"
                                            class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                        >
                                            <span
                                                class="svg-icon svg-icon-md svg-icon-primary"
                                            >
                                                <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                                                <inline-svg
                                                    src="media/svg/icons/Communication/Write.svg"
                                                    :title="$t('EDIT')"
                                                ></inline-svg>
                                                <!--end::Svg Icon-->
                                            </span>
                                        </router-link>
                                        <a
                                            v-on:click="confirmDelete(brand.id)"
                                            class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                        >
                                            <span
                                                class="svg-icon svg-icon-md svg-icon-primary"
                                            >
                                                <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                                                <inline-svg
                                                    src="media/svg/icons/General/Trash.svg"
                                                    :title="$t('DELETE')"
                                                ></inline-svg>
                                                <!--end::Svg Icon-->
                                            </span>
                                        </a>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <b-pagination
                        v-if="brands.length > pagination.per_page"
                        class="pt-5"
                        v-model="pagination.current_page"
                        :total-rows="brands.length"
                        :per-page="pagination.per_page"
                        align="right"
                    >
                    </b-pagination>
                </div>
                <!--end::Table-->
            </div>
            <!--end::Body-->
        </div>
        <!--end::Advance Table Widget 10-->
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import ApiService from "@/core/services/api.service"
import Swal from "sweetalert2"
import { mapGetters } from "vuex"

export default {
    name: "brands",
    data() {
        return {
            brands: [],
            alert: {
                show: false,
                message: "",
                variant: "",
            },
            pagination: {
                current_page: 1,
                per_page: 20,
            },
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: this.$t("BRAND.BRANDS") },
        ])
        ApiService.get("brands")
            .then(({ data }) => {
                this.brands = data.filter((brand) => {
                    return brand.id !== this.currentUser.brand_id
                })
            })
            .catch(() => {
                //Just keep it empty
            })
    },
    methods: {
        confirmDelete: function (id) {
            const brand_index = this.brands.findIndex(
                (brand) => brand.id === id
            )
            const brand = this.brands.find((brand) => brand.id === id)
            Swal.fire({
                title: this.$t("BRAND.DELETE_TITLE", { name: brand.name }),
                text: this.$t("BRAND.DELETE_TEXT"),
                icon: "error",
                showCancelButton: true,
                cancelButtonText: this.$t("CANCEL"),
                confirmButtonText: this.$t("DELETE"),
                buttonsStyling: false,
                customClass: {
                    cancelButton: "btn btn-secondary",
                    confirmButton: "btn btn-danger",
                },
                heightAuto: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiService.delete("brand/" + id)
                        .then(() => {
                            this.brands.splice(brand_index, 1)
                            this.showAlert(
                                this.$t("BRAND.DELETE_SUCCESS", {
                                    name: brand.name,
                                }),
                                "success"
                            )
                        })
                        .catch(() => {
                            this.showAlert(
                                this.$t("BRAND.DELETE_FAILURE", {
                                    name: brand.name,
                                }),
                                "danger"
                            )
                        })
                }
            })
        },
        showAlert(text, variant) {
            this.alert.text = text
            this.alert.variant = variant
            this.alert.show = true
            setTimeout(() => (this.alert.show = false), 5000)
        },
    },
    computed: {
        ...mapGetters(["currentUser"]),
        paginatedBrands() {
            const start =
                    (this.pagination.current_page - 1) *
                    this.pagination.per_page,
                end = start + this.pagination.per_page
            return this.brands.slice(start, end)
        },
    },
}
</script>
